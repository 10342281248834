import React from "react";
//import Datatable from '../../../../../components/Datatable'
//import Datatable from '../../../../routes/table/routes/data/Components/DataTable'
import Datatable from "../../../components/routes/tables/DataTable";
import RowTable from "./rowTable";
import fetchData from "../../../../../services/api/FetchData";
//import Loader from "../../../../../components/Loader"
import Loader from "../../../../../components/CircularProgress";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { get, post, update, destroy } from "../../../../../services/api/http.service";

export default class List extends React.Component {
  state = {
    data: [],
    trademarks: [],
    rewards: [],
    mission_types: [],
    status: [],
    active: false,

    headers: [
      { id: "title", numeric: false, disablePadding: false, label: "Título" },
      /*{
        id: "trademark",
        numeric: false,
        disablePadding: false,
        label: "Marca",
      },*/
      {
        id: "limit_registers",
        numeric: false,
        disablePadding: false,
        label: "Límite de Inscritos",
      },
      {
        id: "amount",
        numeric: true,
        disablePadding: false,
        label: "Puntos",
      },
      {
        id: "social_network_name",
        numeric: false,
        disablePadding: false,
        label: "Plataforma",
      },
      {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Estado",
      },
      {
        id: "actions",
        numeric: true,
        disablePadding: false,
        label: "Acciones",
      },
    ],
  };

  setData = async (route, dataName) => {
    try {
      let url = '';
      if (localStorage.getItem('admin') == '1') {
        url = route;
      } else {
        url = route + '/client/' + localStorage.getItem('client_id');
      }

      const response = await get(url);
      if (response.success) {
        //const data = response.rows.filter(({ active }) => !!active);
        const data = response.rows;

        //data.trademark_name = this.state.trademarks.find(row => row.id == data.trademark_id).
        /*for(let iCont in data){
            //data[iCont]
            data[iCont].trademarks_name = this.state.trademarks.find(row => row.id == data[iCont].trademarks_id).name
            data[iCont].mission_types_name = this.state.mission_types.find(row => row.id == data[iCont].mission_types_id).name
            data[iCont].status_name = this.state.status.find(row => row.id == data[iCont].statusId).name
        }*/


        this.setState({
          ...this.state,
          [dataName]: data,
          dataFiltred: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async getTrademarks(){
    try{
        let response = await get("trademarks")
        if(response.success){
            //trademarks = response.rows
            this.setState({
                ...this.state,
                trademarks: response.rows
            });
        }
    } catch(err){
        console.log("Error: ", err)
    }
  }

  async getRewards() {
    try{
        let response = await get("rewards");
        if(response.success){
            this.setState({
                ...this.state,
                rewards: response.rows
            });
        }
    } catch(err) {
        console.log("Error: ", err)
    }
}

  async getMissionTypes(){
      try{
          let response = await get("mission_types")
          if(response.success){
              //trademarks = response.rows
              this.setState({
                  ...this.state,
                  mission_types: response.rows
              });
          }
      } catch(err){
          console.log("Error: ", err)
      }
  }

  async getStatus(){
      try{
          let response = await get("status")
          if(response.success){
              //trademarks = response.rows
              this.setState({
                  ...this.state,
                  status: response.rows.filter(status => status.status_typeId === 4) // Solo estatus de misiones 
              });
          }
      } catch(err){
          console.log("Error: ", err)
      }
  }

  async componentDidMount() {
    await this.getTrademarks()
    await this.getRewards()
    await this.getMissionTypes()
    //await this.getStatus()
    await this.setData(`missions`, "data");
  }

  reloadData = () => window.location.href = "/app/missions/"//this.props.history.push("/app/missions/");

  render() {
    let permissions = JSON.parse(localStorage.getItem('permissions'));

    if (permissions === null || permissions === undefined) {
      permissions = [];
    }
    
    let permission = permissions.filter(perm => {
      if (perm.section === 'mission') {
        return perm;
      }
    });

    if (permission.lenght === 0 || permission[0].can_read === false) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-danger" role="alert">
                No tienes permisos para ver esta sección
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 mt-3">
            {
              permission[0].can_write &&
              <Button>
                <Link className="btn btn-primary text-white" to={`/app/missions/create`}>
                  Crear Misión
                </Link>
              </Button>
            }
          </div>
          <div className="col-md-9">
          </div>
          <div className="col-md-12  mr-2">
            <Datatable
              RowComponent={RowTable}
              data={this.state.data}
              headers={this.state.headers}
              reloadData={this.reloadData}
              title="Misiones"
            />
          </div>
        </div>
      </div>
    );
  }
}
