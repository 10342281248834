import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import { TableRow, TableCell, Button, TextField } from "@material-ui/core";

import Options from "./options";

const RowTable = props => {
  const { isItemSelected, row } = props;
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [menuState, setMenuState] = useState(false);

  const openMenuOptions = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const closeMenuOptions = () => {
    setMenuState(false);
  };
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell align="left">{row.title || "No registrado"}</TableCell>
      <TableCell align="left">{row.limit_registers || "No registrado"}</TableCell>
      <TableCell align="center">{row.amount}</TableCell>
      <TableCell align="left">{row.social_network_name || "No registrado"}</TableCell>
      <TableCell align="left">{row.active ? 'Activa' : 'Desactivada'}</TableCell>
      <TableCell align="right">
        <IconButton onClick={openMenuOptions}>
          <MoreVert />
        </IconButton>
        <Options
          menuState={menuState}
          anchorEl={anchorEl}
          handleRequestClose={closeMenuOptions}
          data={row}
          id={row.id}
          status={row.active}
        />
      </TableCell>
    </TableRow>
  );
};

export default RowTable;
